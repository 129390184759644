import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

(function(window, document) {
  function handleSidebarToggling() {
    function openSidebar(e) {
      var sidebarContainer = e.currentTarget.closest('.sidebar-container');
      sidebarContainer.classList.toggle('sidebar-opened');
      var sidebarPusher = sidebarContainer.querySelector('.sidebar-pusher');
      sidebarPusher.addEventListener('click', closeSidebar);
    }
    function closeSidebar(e) {
      e.currentTarget
        .closest('.sidebar-container')
        .classList.remove('sidebar-opened');
      e.currentTarget.removeEventListener('click', closeSidebar);
    }

    var sidebarToggles = document.querySelectorAll('.sidebar-toggle');
    Array.prototype.forEach.call(sidebarToggles, function(sidebarToggle) {
      sidebarToggle.addEventListener('click', openSidebar);
    });
  }
  // function showMailingPopUp() {
  //   window.dojoRequire(['mojo/signup-forms/Loader'], function(L) {
  //     L.start({
  //       baseUrl: 'mc.us20.list-manage.com',
  //       uuid: 'f31633b7c90e457b980571f08',
  //       lid: 'ed3b7b6c48',
  //       uniqueMethods: true
  //     });
  //   });
  // }

  // document.querySelector('.openPopup').onclick = function() {
  //   showMailingPopUp();
  // };

  handleSidebarToggling();
})(window, document);
serviceWorker.unregister();
