import React, { Component, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {ChakraProvider} from "@chakra-ui/react"

import "./styles/bootstrap.css";
// import './styles/font-awesome/font-awesome.min.css';
import "./styles/font-awesome/css/all.css";
import "./styles/animate.css";
import "./styles/scss/styles.scss";
import "./styles/scss/responsive.scss";

import Preloader from "./components/shared/preloader";
import ErrorBoundary from "./components/shared/error-boundary";

const ScrollTop = lazy(() => import("./components/shared/scroll-top"));
const Error404 = lazy(() => import("./components/errors/error404"));
const AdminLoginPage = lazy(() => import("./components/admin/admin-login"));
const AdminGuard = lazy(() => import("./components/guards/admin-guards"));

class App extends Component {
  render() {
    return (
      <Router>
        <ChakraProvider>
          <ErrorBoundary>
            <Suspense fallback={<Preloader />}>
              <div className="App">
                <Switch>
                  <Route exact path="/" component={AdminLoginPage} />
                  <Route exact path="/admin" component={AdminLoginPage} />
                  <Route exact path="/admin/dashboard" component={AdminGuard} />
                  <Route
                    exact
                    path="/admin/loan-requests"
                    component={AdminGuard}
                  />
                  <Route
                    exact
                    path="/admin/loan-requests/:id"
                    component={AdminGuard}
                  />
                  <Route
                    exact
                    path="/admin/approved-loans"
                    component={AdminGuard}
                  />
                  <Route
                    exact
                    path="/admin/declined-loans"
                    component={AdminGuard}
                  />
                  <Route exact path="/admin/users" component={AdminGuard} />
                  <Route
                    exact
                    path="/admin/all-customer-with-cards"
                    component={AdminGuard}
                  />
                  <Route exact path="/admin/visitors" component={AdminGuard} />
                  <Route
                    exact
                    path="/admin/transactions"
                    component={AdminGuard}
                  />
                  <Route
                    exact
                    path="/admin/loans-for-the-week"
                    component={AdminGuard}
                  />
                  <Route
                    exact
                    path="/admin/loans-past-due-date"
                    component={AdminGuard}
                  />
                  <Route exact path="/admin/user/:id" component={AdminGuard} />
                  <Route
                    exact
                    path="/admin/create-a-user"
                    component={AdminGuard}
                  />
                  <Route
                    exact
                    path="/admin/send-custom-email"
                    component={AdminGuard}
                  />
                  <Route path="*" component={Error404} />
                </Switch>
                <ScrollTop />
              </div>
            </Suspense>
          </ErrorBoundary>
        </ChakraProvider>
      </Router>
    );
  }
}

export default App;
